import { ValidationObject } from 'components/bricks/types/validator.type';

// Settings data from the brick object
const brickSettings = 'brick.data?.settings?';
// Extract the objective type from the parent brick's settings (used to determine valid optimization goals)
const parentObjectiveType = 'parent?.data?.settings.objective_v2_properties.objective_v2_type';

const validators: ValidationObject = {
    validators: [
        {
            condition: `!${brickSettings}.status || !brick?.data?.settings?.status.length`,
            message: 'Status is required',
            inputIdentifiers: ['snapchat_ad_squad-status'],
            tabKey: 'settings',
            severity: 'error'
        },
        {
            condition: '!brick.title || !brick.title.length',
            message: 'Ad squad title is required',
            inputIdentifiers: ['title'],
            tabKey: 'settings',
            severity: 'error'
        },
        {
            condition: `!${brickSettings}.billing_event || !brick?.data?.settings?.billing_event.length`,
            message: 'Billing event is required',
            inputIdentifiers: ['snapchat_ad_squad-billing_event'],
            tabKey: 'settings',
            severity: 'error'
        },
        {
            condition: `${brickSettings}.start_time &&
                new Date(new Date(brick.data.settings.start_time).setHours(0, 0, 0, 0)) <
                new Date(new Date().setHours(0, 0, 0, 0))
            `,
            message: 'Start date cannot be in the past',
            inputIdentifiers: ['snapchat_ad_squad-start_time'],
            tabKey: 'settings',
            severity: 'error'
        },
        {
            condition: `${brickSettings}.end_time &&
                new Date(new Date(brick.data.settings.end_time).setHours(0, 0, 0, 0)) <
                new Date(new Date().setHours(0, 0, 0, 0))
            `,
            message: 'End date cannot be in the past',
            inputIdentifiers: ['snapchat_ad_squad-end_time'],
            tabKey: 'settings',
            severity: 'error'
        },
        {
            condition: `
                (${brickSettings}.delivery_constraint === 'DAILY_BUDGET' &&
                    (!${brickSettings}.budget || ${brickSettings}.budget === '')) ||
                (${brickSettings}.delivery_constraint === 'LIFETIME_BUDGET' &&
                    (!${brickSettings}.budget || ${brickSettings}.budget === ''))
            `,
            message: 'Budget is required under the current delivery constraint',
            inputIdentifiers: ['snapchat_ad_squad-budget', 'snapchat_ad_squad-budget'],
            tabKey: 'settings',
            severity: 'error'
        },
        {
            condition: `${brickSettings}.delivery_constraint === 'DAILY_BUDGET' && ${brickSettings}.budget < 5`,
            message: 'The ad set daily budget must be at least 5',
            inputIdentifiers: ['snapchat_ad_squad-budget'],
            tabKey: 'settings',
            severity: 'error'
        },
        {
            condition: `${brickSettings}.delivery_constraint === 'LIFETIME_BUDGET' && ${brickSettings}.budget < 40`,
            message: 'The ad set lifetime budget must be at least 40',
            inputIdentifiers: ['snapchat_ad_squad-budget'],
            tabKey: 'settings',
            severity: 'error'
        },
        {
            condition: `!${brickSettings}.optimization_goal || !${brickSettings}.optimization_goal.length`,
            message: 'Optimization goal is required',
            inputIdentifiers: ['snapchat_ad_squad-optimization_goal'],
            tabKey: 'settings',
            severity: 'error'
        },
        // Optimization goal validations based on objective type
        {
            condition: `${parentObjectiveType} === 'AWARENESS_AND_ENGAGEMENT' &&
        ![
            'IMPRESSIONS',
            'SWIPES',
            'STORY_OPENS',
            'USES',
            'VIDEO_VIEWS',
            'VIDEO_VIEWS_15_SEC'
        ].includes(${brickSettings}.optimization_goal)`,
            message:
                'For Awareness & Engagement objective, optimization goal must be one of: Impressions, Swipe Ups, Story Opens, Filter Uses, 2-Second Video Views, or 15-Second Video Views.',
            inputIdentifiers: ['snapchat_ad_squad-optimization_goal'],
            tabKey: 'settings',
            severity: 'error'
        },

        {
            condition: `
        ${parentObjectiveType} === 'APP_PROMOTION' &&
        ![
            'IMPRESSIONS',
            'SWIPES',
            'APP_INSTALLS',
            'APP_PURCHASE',
            'APP_SIGNUP',
            'APP_ADD_TO_CART',
            'APP_LEVEL_COMPLETE',
            'APP_ACHIEVEMENT_UNLOCKED',
            'APP_AD_VIEW',
            'LANDING_PAGE_VIEW',
            'APP_REENGAGE_PURCHASE',
            'APP_REENGAGE_OPEN'
        ].includes(${brickSettings}.optimization_goal)`,
            message:
                'For App Promotion objective, optimization goal must be one of the allowed Snapchat app actions (e.g. installs, purchases, re-engagements).',
            inputIdentifiers: ['snapchat_ad_squad-optimization_goal'],
            tabKey: 'settings',
            severity: 'error'
        },
        {
            condition: `${parentObjectiveType} === 'SALES' &&
        ![
            'SWIPES',
            'STORY_OPENS',
            'PIXEL_PURCHASE',
            'PIXEL_SIGNUP',
            'PIXEL_ADD_TO_CART',
            'PIXEL_PAGE_VIEW',
            'LANDING_PAGE_VIEW',
            'IMPRESSIONS',
            'APP_REENGAGE_PURCHASE',
            'APP_REENGAGE_OPEN'
        ].includes(${brickSettings}.optimization_goal)`,
            message: 'For Sales objective, optimization goal must relate to conversions like purchases, sign-ups, or engagement actions.',
            inputIdentifiers: ['snapchat_ad_squad-optimization_goal'],
            tabKey: 'settings',
            severity: 'error'
        },
        {
            condition: `${parentObjectiveType} === 'LEADS' &&
        ![
            'SWIPES',
            'STORY_OPENS',
            'PIXEL_SIGNUP',
            'LANDING_PAGE_VIEW',
            'LEAD_FORM_SUBMISSIONS',
            'IMPRESSIONS'
        ].includes(${brickSettings}.optimization_goal)`,
            message: 'For Leads objective, optimization goal must be one of: Lead Form Submissions, Sign Ups, Landing Page Views, etc.',
            inputIdentifiers: ['snapchat_ad_squad-optimization_goal'],
            tabKey: 'settings',
            severity: 'error'
        },
        {
            condition: `${parentObjectiveType} === 'TRAFFIC' &&
        ![
            'SWIPES',
            'PIXEL_PAGE_VIEW',
            'LANDING_PAGE_VIEW',
            'APP_REENGAGE_PURCHASE',
            'APP_REENGAGE_OPEN',
            'IMPRESSIONS'
        ].includes(${brickSettings}.optimization_goal)`,
            message: 'For Traffic objective, optimization goal must be one of: Swipe Ups, Page Views, or Re-engagements.',
            inputIdentifiers: ['snapchat_ad_squad-optimization_goal'],
            tabKey: 'settings',
            severity: 'error'
        },
        {
            condition: `!${brickSettings}.targeting?.geos || ${brickSettings}.targeting.geos.length === 0`,
            message: 'At least one location is required',
            inputIdentifiers: ['snapchat_ad_squad-targeting-geos'],
            tabKey: 'targeting',
            severity: 'error'
        },
        {
            condition: `
                brick.data.settings?.bid_strategy !== 'AUTO_BID' &&
                (!brick.data.settings?.bid_micro || brick.data.settings?.bid_micro === '')
            `,
            message: 'Bid price is required when bid strategy is "Lowest cost with max bid"',
            inputIdentifiers: ['snapchat_ad_squad-bid_micro'],
            tabKey: 'settings',
            severity: 'error'
        }
    ]
};

export default validators;
